import React, { useEffect, useState } from 'react';
import { forEach, isEmpty } from 'lodash';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

import EmploymentModals from './EmploymentModals';
import EmploymentSaved from './EmploymentSaved/EmploymentSaved';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BGVConfirmDialog from '../../../CommonComponent/BGVConfirmDialog';
import ProgressBar from '../../../../../core-components/ProgressBar';
import Image from '../../../../../core-components/Image';
import logger from '../../../../../core-components/Logger';

import styles from './Employment.module.scss';
import { isAllRefFilled } from './EmploymentHelper';
import AppConstants from '../../../../../core-components/AppConstants';
const EmploymentFunc = (props) => {
  const [modalShow, setmodalShow] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [showFinalSubmit, setshowFinalSubmit] = useState(
    props.finalSubmitCheck === 4 ? true : false
  );
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [currentSavingIndex, setcurrentSavingIndex] = useState(0);
  const [empToCollect, setEmpToCollect] = useState(0);
  const [skipEmployment, setskipEmployment] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    setskipEmployment(props.cached_data.skipped_employment);
    getTotalEmployment();
  }, []);
  const { isSubmitting } = props;
  const isLwdOptionalInEmployment =
    AppConstants?.companyIdsWWithBgvNonMandatoryFields?.[
      props?.bgvPackageConfig?.company?.id
    ]?.includes('LWD');
  const isValidFormCheck = () => {
    let empData = props?.cached_data?.cache_data?.employment_check?.data
      ? props.cached_data?.cache_data?.employment_check.data
      : null;
    let pendingIndex = checkPendingEmployment();
    return (
      (pendingIndex === null && empData !== null && empData.length === parseInt(empToCollect)) ||
      parseInt(empToCollect) === 0 ||
      skipEmployment
    );
  };
  const checkAllFieldsFilled = () => {
    return props?.cached_data?.cache_data?.employment_check?.data?.every((emp) =>
      isAllRefFilled(emp)
    );
  };
  const checkPendingEmployment = () => {
    let empData = props?.cached_data?.cache_data?.employment_check?.data
      ? props.cached_data?.cache_data?.employment_check?.data
      : null;
    let pendingIndex = null;
    if (empData !== null) {
      forEach(empData, function (value, key) {
        if (value && Object.keys(value).length < 3) {
          pendingIndex = key;
        }
      });
    }
    return pendingIndex;
  };
  const empData = props?.cached_data?.cache_data?.employment_check?.data
    ? props.cached_data?.cache_data?.employment_check?.data
    : null;
  const isValidForm = !isSubmitting && isValidFormCheck();
  const indexing =
    props.totalSteps
      .map((val) => {
        return val?.id;
      })
      .indexOf(4) + 1;
  const setIsEdit = (index) => {
    setModalShow(index);
  };

  const setModalShow = (index = null) => {
    const currentEmpIndex =
      index === null
        ? props.employmentCheck && props.employmentCheck?.length
          ? props.employmentCheck?.length
          : 0
        : index;
    setcurrentSavingIndex(currentEmpIndex);
    props.invalidateEmpDocType();
    props.invalidateGradesheetUpload();
    setisEdit(index !== null ? true : false);
    setmodalShow(true);
  };
  useEffect(() => {
    if (
      props.employmentCheck &&
      props.employmentCheck.length &&
      props.employmentCheck[currentSavingIndex]
    ) {
      const value = props.employmentCheck[currentSavingIndex];
      if (
        value.step_2 &&
        value.step_2.doc_type &&
        value.step_2.proof_doc &&
        value.step_2.proof_doc.length
      ) {
        props.setEmpDocType(value.step_2.doc_type);
        for (let i in value.step_2.proof_doc) {
          props.setGradesheetUpload({
            ...value.step_2.proof_doc[parseInt(i)],
            index: parseInt(i)
          });
        }
      }
    }
  }, [currentSavingIndex]);

  const setModalHide = () => {
    setmodalShow(false);
    props.invalidateEmpDocType();
    props.invalidateGradesheetUpload();
  };
  const baseReferenceObj = {
    full_name: '',
    email: '',
    country_code: '+91',
    isValidPhone: true,
    phone: '',
    designation: '',
    linkedin_url: '',
    phone_number: ''
  };
  const baseManagerObj = {
    full_name: '',
    email: '',
    country_code: '+91',
    isValidPhone: true,
    refCount: 0,
    phone: '',
    phone_number: ''
  };
  const fillReferenceEmp = (index, employmentObj) => {
    let adRef = employmentObj?.step_3?.references_additional;
    const requiredRef = parseInt(props.bgvPackageConfig.config.reference[index]);
    if (requiredRef > adRef?.length) {
      // if additonal ref is empty, fill from manager info
      if (adRef?.length === 0) {
        adRef = [
          employmentObj?.step_3?.manager_info,
          ...new Array(requiredRef - 1).fill(baseReferenceObj)
        ];
      } else {
        adRef = [...adRef, ...new Array(requiredRef - adRef?.length).fill(baseReferenceObj)];
      }
      // fill extra
    } else if (requiredRef < adRef?.length) {
      adRef.splice(requiredRef);
    }
    return adRef;
  };
  const fillManager = (index, employmentObj) => {
    const requiredRef = parseInt(props.bgvPackageConfig.config.reference[index]);
    let adRef = employmentObj?.step_3?.references_additional;
    let manager_info = baseManagerObj;
    if (requiredRef === 0) {
      if (adRef?.length > 0) {
        manager_info = adRef[0];
        delete manager_info.linkedin_url;
        delete manager_info.designation;
      } else {
        manager_info = employmentObj?.step_3?.manager_info;
      }
    }
    manager_info.refCount = requiredRef;
    return manager_info;
  };
  const handleEmpDelete = (index) => {
    setIsDeleting(true);
    let finalStepObj = [...props?.cached_data?.cache_data?.employment_check?.data];
    finalStepObj.splice(index, 1); //deleting array of particular index
    let cacheData = { ...props.cached_data.cache_data };
    let isSkipped = props.cached_data.cache_data.skipped_employment || false;
    if (finalStepObj.length) {
      const allEmpCheck = finalStepObj.map((final, i) => {
        if (final?.step_3) {
          return {
            ...final,
            step_3: {
              ...final.step_3,
              refCount: parseInt(props.bgvPackageConfig.config.reference[i]),
              manager_info: fillManager(i, final),
              references_additional: fillReferenceEmp(i, final)
            }
          };
        } else {
          return final;
        }
      });
      cacheData = {
        ...cacheData,
        employment_check: { ...cacheData?.employment_check, data: [...allEmpCheck] }
      };
    } else {
      cacheData = { ...cacheData };
      delete cacheData['employment_check'].data;
    }
    if (empToCollect > 0 && isEmpty(cacheData['employment_check'])) {
      isSkipped = false;
      setskipEmployment(false);
    }

    //formatting cache data to save
    const finalCacheData = {
      saveObject: {
        ...props.cached_data,
        cache_data: { ...cacheData },
        skipped_employment: isSkipped,
        current_state: '4'
      }
    };
    props
      .setBgvCache(finalCacheData)
      .then((response) => {
        const loggerObj = {
          message: `${index}th index employment is deleted`,
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Employment details',
          type: 'info'
        };
        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handleEmpEdit = (index) => {
    setIsEdit(index);
  };
  const checkEmployment = () => {
    if (!isEmpty(props?.cached_data?.cache_data?.employment_check?.data)) {
      const employmentChecksArray = props?.cached_data?.cache_data?.employment_check?.data.map(
        (d) => {
          return (
            d.hasOwnProperty('step_1') &&
            !isEmpty(d.step_1) &&
            d.hasOwnProperty('step_2') &&
            !isEmpty(d.step_2) &&
            d.hasOwnProperty('step_3') &&
            !isEmpty(d.step_3)
          );
        }
      );
      return employmentChecksArray?.every((check) => check === true);
    } else {
      return false;
    }
  };
  const getTotalEmployment = () => {
    const reference = props.bgvPackageConfig.config.reference;
    const employment = props.bgvPackageConfig.config.employment;
    const lastNonZeroIndex = Array.isArray(reference)
      ? reference?.reduceRight((acc, curr, index) => {
          return curr != 0 && acc === -1 ? index : acc;
        }, -1)
      : -1;
    const totalEmploymentFromReference = lastNonZeroIndex !== -1 ? lastNonZeroIndex + 1 : 0;
    const totalEmployment = Math.max(employment, totalEmploymentFromReference);
    setEmpToCollect(totalEmployment);
  };

  const handleFinalSubmit = () => {
    props.handleSubmit();
  };

  const handleFinalConfirmModal = () => {
    setshowConfirmation(false);
    props.setSubmitting(false);
  };

  const handleStepSubmit = () => {
    if (showFinalSubmit) {
      setshowConfirmation(true);
    } else {
      props.handleSubmit();
    }
  };
  const skipEmploymentCheck = (e) => {
    setskipEmployment(e.target.checked);
    const loggerObj = {
      message: `Employment Main Page Further Checkbox - ${e.target.checked}`,
      data: JSON.stringify(props.cached_data?.cache_data?.employment_check),
      category: `candidate-id-${props.candidateId}`,
      subCategory: 'Employment',
      type: 'info'
    };
    logger.push(loggerObj);

    if (e.target.checked) {
      let finalStepObj = props?.cached_data?.cache_data?.employment_check?.data
        ? [...props.cached_data?.cache_data?.employment_check?.data]
        : [];
      let cacheData = { ...props.cached_data.cache_data };

      if (finalStepObj.length) {
        cacheData = { ...cacheData, employment_check: { data: [...finalStepObj] } };
      } else {
        cacheData = { ...cacheData };
        delete cacheData['employment_check'];
      }

      let saveObject = {
        ...props.cached_data,
        cache_data: { ...cacheData },
        current_state: '4',
        skipped_employment: true
      };

      saveToCache(saveObject);
    } else {
      let saveObject = {
        ...props.cached_data,
        current_state: '4',
        skipped_employment: false
      };
      saveToCache(saveObject);
    }
  };

  const saveToCache = (saveObject) => {
    //formatting cache data to save
    const finalCacheData = {
      saveObject
    };

    props
      .setBgvCache(finalCacheData)
      .then()
      .catch((error) => {
        console.error(error);
      });
  };
  const showSkipCheck = () => {
    return [
      empToCollect > 0,
      props?.cached_data?.cache_data?.employment_check?.data?.length > 0,
      props?.cached_data?.cache_data?.employment_check?.data?.length !== empToCollect,
      checkEmployment(),
      checkAllFieldsFilled()
    ].every((item) => item === true);
  };
  return (
    <Card className={styles.employmentCard}>
      <Card.Body>
        <Row>
          <Col sm={12} xs={12} md={12} lg={12}>
            {!isMobileOnly ? (
              <>
                <span className={styles.employmentHeading}>Employment</span>
                <div className={styles.employmentProgressContainer}>
                  <span className={styles.stepText}>
                    {indexing} of {props.totalSteps.length}
                  </span>
                  <ProgressBar max={props.totalSteps.length} min={0} now={indexing} />
                </div>
              </>
            ) : (
              <>
                <BgvSecureText />
                <div className={styles.employmentProgressContainer}>
                  <span className={styles.employmentHeading}>Employment</span>
                  <span className={styles.stepText}>
                    {indexing} of {props.totalSteps.length}
                  </span>
                </div>
                <ProgressBar max={props.totalSteps.length} min={0} now={indexing} />
              </>
            )}
          </Col>
        </Row>
        <div className={styles.employmentTitle}>{`Add your
          ${empToCollect || 0} most recent work experiences`}</div>
        <div className={styles.employmentSubtitle}>
          <p>Add the work experience details and relevant documents as proof.</p>
          <p className={styles.employmentSubtitleInfo}>
            Your employer requires verification of your most recent work experiences. Please enter
            your latest employment first and the subsequent employment later.
          </p>
        </div>
        {empData?.map((emp, key) => {
          if (!isEmpty(emp)) {
            return (
              <EmploymentSaved
                item={emp}
                index={key}
                key={key}
                showhr={key !== 0 ? true : false}
                handleEmpDelete={handleEmpDelete}
                handleEmpEdit={handleEmpEdit}
                loading={isDeleting}
                isLwdOptionalInEmployment={isLwdOptionalInEmployment}
              />
            );
          }
        })}
        {/* add employment button */}
        {empToCollect > 0 && empToCollect !== empData?.length && !skipEmployment && (
          <Row className={styles.addIdBody}>
            <Col sm={12} xs={12} md={10} lg={5}>
              <Button
                className={styles.addBtn}
                onClick={() => {
                  setModalShow(null);
                }}
                disabled={skipEmployment}
              >
                <Image className={styles.addSign} name='AddId.svg' />
                Add Employment
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
      {modalShow && (
        <EmploymentModals
          show={modalShow}
          onHide={() => setModalHide()}
          currentSavingIndex={currentSavingIndex}
          setCurrentSavingIndex={setcurrentSavingIndex}
          isEdit={isEdit}
          {...props}
        />
      )}
      {/* Confirm Dialog */}
      {showConfirmation && showFinalSubmit && (
        <BGVConfirmDialog
          showConfirmation={showConfirmation}
          handleConfirmSubmit={handleFinalSubmit}
          handleHide={handleFinalConfirmModal}
          enableBtn={isValidForm}
          {...props}
        />
      )}
      <Card.Footer className={styles.empFooter}>
        <Row>
          <Col sm={12} xs={12} md={12} lg={12} className={styles.checkBoxContainer}>
            {showSkipCheck() ? (
              <Form.Check
                checked={skipEmployment}
                type={'checkbox'}
                id='isSkipEducation'
                name='isSkipEducation'
                label={`I don't have any further details than what is provided`}
                onChange={skipEmploymentCheck}
              />
            ) : null}
          </Col>
          <Col sm={12} xs={12} md={12} lg={12} className={styles.employmentSubmitBtn}>
            {/* Added common prev button */}
            <BgvGoBackBtn handleClick={props.prevStep} />
            <Button
              type='submit'
              className={
                isValidForm &&
                !props.isLoadingBgvCache &&
                !props.internalLoading &&
                checkAllFieldsFilled()
                  ? styles.activeBtn
                  : styles.disabledBtn
              }
              disabled={
                !isValidForm ||
                props.isLoadingBgvCache ||
                props.internalLoading ||
                !checkAllFieldsFilled()
              }
              onClick={() => {
                handleStepSubmit();
              }}
            >
              {showFinalSubmit ? 'Submit' : 'Next'}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default EmploymentFunc;
