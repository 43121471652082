// React and libraries imports
import React, { useEffect, useState } from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { Field } from 'formik';
import { isEmpty, has, capitalize } from 'lodash';
import Select from 'react-select';

// Core components and Utils import
import Maps from './Maps';
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import ValidatedRadioFields from '../../../../../../../core-components/ValidatedRadioFields';
import CustomValidatedSelect from '../../../../../../../core-components/CustomValidatedSelect';
import Button from '../../../../../../../core-components/Button';
import countryOptions from '../../../../../../../utils/countryCodesEn.json';
import countryNames from '../../../../../../../utils/countryNamesEn.json';
import { getTrimmedValue, isValidPhoneNumber } from '../../../../../../../utils/utilities';

// Styles import
import styles from './AddressStep2.module.scss';
import { capitalizeName } from '../../../../../../CompanyAdmin/frontend-common/utils/Utilities';
import { getAddressGeocode } from '../../../../../../../api/company';

export default (props) => {
  const { setFieldValue, touched, setFieldTouched, isValid, isSubmitting, values, cachedData } =
    props;

  const [step1Address, setStep1Address] = useState(0);
  const step2Address = props?.cachedData?.cacheData?.addressCheck[props?.index].step2;
  const [isValidPhone, setIsValidPhone] = useState(true);
  const stayValues = [
    'Under 6 months',
    '6 months to 1 year',
    '1 year',
    '2 years',
    '3 years',
    'More than 3 years'
  ];
  const relationValues = ['Family', 'Friend', 'Tenant', 'Property Owner', 'Other'];

  useEffect(() => {
    // this is for maps requirement
    if (
      has(props, 'cachedData') &&
      !isEmpty(props.cachedData) &&
      has(props.cachedData, 'cacheData') &&
      !isEmpty(props.cachedData.cacheData) &&
      // props.cachedData.cacheData &&
      has(props.cachedData.cacheData, 'addressCheck') &&
      props.cachedData.cacheData.addressCheck.length &&
      has(props.cachedData.cacheData.addressCheck[props.index], 'step1')
    ) {
      const step1data = props?.cachedData?.cacheData?.addressCheck?.[props.index]?.['step1'];
      setStep1Address(step1data);

      if (step1data) {
        getAddressGeocode({ postalCode: step1data.pincode, country: step1data.country }).then(
          (res) => {
            const geoData = res.data;
            setFieldValue('latitude', geoData?.results[0]?.geometry?.location?.lat);
            setFieldValue('longitude', geoData?.results[0]?.geometry?.location?.lng);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    let phone = props.values.relativeCountryCode + props.values.relativePhoneNumber;
    setFieldValue('otherPhoneNumber', phone);
  }, [props.values.relativeCountryCode, props.values.relativePhoneNumber]);

  const setCurrentLocation = (val) => {
    setFieldValue('latitude', val?.latlng.latitude);
    setFieldValue('longitude', val?.latlng.longitude);
    setFieldValue('placeId', val?.placeId);
    setFieldValue('mapAddress', val?.mapAddress);
  };

  const whenBlockedLocation = (val) => {
    setFieldValue('latitude', val?.latlng.lat);
    setFieldValue('longitude', val?.latlng.lng);
    setFieldValue('placeId', val?.placeId);
    setFieldValue('mapAddress', val?.mapAddress);
  };

  const validatePhoneNumber = (countryCode, phoneNumber) => {
    let intlPhoneNumber = `${countryCode}${phoneNumber}`;
    if (!isValidPhoneNumber(intlPhoneNumber)) {
      setIsValidPhone(false);
    } else {
      setIsValidPhone(true);
    }
  };
  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };

  const handleLocationReset = (data) => {
    setFieldValue('latitude', data.latlng.lat);
    setFieldValue('longitude', data.latlng.lng);
    setFieldValue('placeId', data.placeId);
    setFieldValue('mapAddress', data.mapAddress);
  };

  return (
    <Modal
      size='lg'
      show={props.show}
      onHide={props.onHide}
      dialogClassName='bgvModalDialog'
      className='bgvModal'
      backdropClassName={'customBGVModalBackdrop'}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className={styles.step2Title}>
            {`${props.title} ${
              props.addressType === 'ANY ADDRESS'
                ? props?.cachedData?.cacheData?.addressCheck[0]?.step1.addressType
                : capitalize(props.addressType) + ' Address'
            }`}
          </div>
          <div className={styles.step2SubTitle}>
            {`Step ${props.currentModalSubStep} of ${props.totalStep}`}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Form>
          {/**
           * Below code is commented as we are not using the map for now as per ADO-23310
           */}
          {/* <Form.Row>
            <Col>
              <div className={styles.warningHeading}>Is the pin in the right place ?</div>
              <p className={styles.warningSubHeading}>
                This will help our agents get to your place ensuring a hassle-free experience for
                you.
              </p>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <div style={{ width: '100%', height: '233px' }}>
                <Maps
                  setLocation={setCurrentLocation}
                  whenBlocked={whenBlockedLocation}
                  address={isEmpty(step2Address) ? step1Address : step2Address}
                  isStep2Address={!isEmpty(step2Address)}
                  savedLatitude={props.values.latitude}
                  savedLongitude={props.values.longitude}
                  step1Address={step1Address}
                  onLocationReset={handleLocationReset}
                />
              </div>
            </Col>
          </Form.Row> */}
          <Form.Row className={styles.Row}>
            <Col>
              <Form.Label>
                How long have you stayed here?<span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <div className={styles.dropdown}>
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={stayValues}
                  value={stayValues?.filter((s) => s === props?.values?.stayingPeriod) || ''}
                  name='stayingPeriod'
                  getOptionLabel={(l) => l}
                  getOptionValue={(v) => v}
                  onChange={(value) => {
                    setFieldValue('stayingPeriod', value);
                  }}
                />
                {props.errors.stayingPeriod && (
                  <div className={styles.error}>{props.errors.stayingPeriod}</div>
                )}
              </div>
            </Col>
          </Form.Row>
          {/* radio button  Is your permanent address and current address the same? */}
          {props.addressType === 'PERMANENT' ? (
            <Form.Row className={styles.Row}>
              <Col>
                <Form.Label>
                  Is your permanent address and current address the same?
                  <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <div className={styles.radioBtn}>
                  <Field
                    name='isCurrentPermanentSame'
                    component={ValidatedRadioFields}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' }
                    ]}
                    onChange={(e) => {
                      const value = e?.target?.value;
                      setFieldValue('isCurrentPermanentSame', value === 'true' ? true : false);
                    }}
                  />
                </div>
              </Col>
            </Form.Row>
          ) : null}
          <Form.Row className={styles.Row}>
            <Col>
              <Form.Label>
                Will you be at this address from 9 AM to 5 PM for verifying your address?
                <span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <div className={styles.radioBtn}>
                <Field
                  name='isCandidateAvailable'
                  component={ValidatedRadioFields}
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' }
                  ]}
                />
              </div>
              {props.errors.isCandidateAvailable && (
                <div className={styles.error}>{props.errors.isCandidateAvailable}</div>
              )}
            </Col>
          </Form.Row>
          {/* if he is not available then this part should popup
           */}
          {props.values.isCandidateAvailable === 'no' && (
            <>
              <Form.Row className={styles.Row}>
                <Col>
                  <div className={styles.warningHeading}>
                    Who can we speak with in your absence?
                  </div>
                  <p className={styles.warningSubHeading}>
                    Our field agent will contact this person help with direction and your
                    verification. Please co-operate with them to ensure a smooth verification
                    process.
                  </p>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label>
                    Their Full Name<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Field
                    component={ValidatedFormInputField}
                    type='text'
                    placeholder='Name'
                    name='otherFullName'
                    capitalizeName
                  />
                  {/* {props.errors.otherFullName && (
                    <div className={styles.error}>{props.errors.otherFullName}</div>
                  )} */}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label>
                    How are they related to you?<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={6} lg={6}>
                  <Select
                    menuPosition={'fixed'}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={relationValues}
                    className={styles.mb32}
                    value={relationValues?.filter((i) => i === props?.values?.relation) || ''}
                    name='relation'
                    getOptionLabel={(l) => l}
                    getOptionValue={(v) => v}
                    onMenuClose={() => {
                      setTimeout(() => {
                        setFieldTouched('relation', true);
                      }, 500);
                    }}
                    onChange={(value) => {
                      setTimeout(() => {
                        setFieldValue('relation', value);
                      }, 100);
                    }}
                  />
                  {props.errors.relation && touched?.relation && (
                    <div className={styles.relationError}>{props.errors.relation}</div>
                  )}
                </Col>
                {props.values.relation === 'Other' && (
                  <Col md={6} lg={6}>
                    <Field
                      component={ValidatedFormInputField}
                      type='text'
                      placeholder='Relation'
                      name='otherRelation'
                      onBlur={(e) => {
                        props.handleBlur(e);
                        setValue('otherRelation', capitalizeName(props.values.otherRelation), true);
                      }}
                    />
                  </Col>
                )}
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label>
                    Their Phone Number<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                </Col>
              </Form.Row>
              <Form.Row className={styles.relativePhoneContainer}>
                <div className={styles.formGridPhoneNumber}>
                  <Form.Group controlId='formGridPhoneNumber' className={styles.cCode}>
                    <div className={styles.countryDropdown}>
                      <Field
                        type='text'
                        name='relativeCountryCode'
                        defaultValue={props?.values?.relativeCountryCode}
                        options={countryNames}
                        className={styles.countryCodeDropDown}
                        filterLabel={countryOptions}
                        component={CustomValidatedSelect}
                        onChange={(countryCode) => {
                          if (
                            props.values.relativePhoneNumber &&
                            countryCode &&
                            !props.errors.relativePhoneNumber
                          ) {
                            validatePhoneNumber(countryCode, props.values.relativePhoneNumber);
                          }
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId='formGridPhoneNumber' className={styles.rPhoneNumber}>
                    <Field
                      name='relativePhoneNumber'
                      className={
                        styles.phoneInput + ' ' + (!isValidPhone ? styles.errorInput : null)
                      }
                      type='number'
                      component={ValidatedFormInputField}
                      onChange={(event) => {
                        setFieldValue('relativePhoneNumber', event.target.value);
                        validatePhoneNumber(
                          props.values.relativeCountryCode,
                          getTrimmedValue(event.target.value, false)
                        );
                      }}
                      isHyphenNotRequired
                      onBlur={(e) => {
                        props.handleBlur(e);
                        if (
                          props.values.relativePhoneNumber &&
                          props.values.relativeCountryCode &&
                          !props.errors.relativePhoneNumber
                        ) {
                          validatePhoneNumber(
                            props.values.relativeCountryCode,
                            getTrimmedValue(props.values.relativePhoneNumber, false)
                          );
                        }
                        setValue(
                          'relativePhoneNumber',
                          getTrimmedValue(props.values.relativePhoneNumber, false),
                          false
                        );
                      }}
                    />
                    {!props.errors.relativePhoneNumber && !isValidPhone && (
                      <div className={styles.errorMsg}>
                        {!isValidPhone ? 'Please enter a valid phone number' : ''}
                      </div>
                    )}
                  </Form.Group>
                </div>
              </Form.Row>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button className={'bgvModalSecondaryBtn'} onClick={() => props.prevSubStep()}>
          Go Back
        </Button>
        <Button
          onClick={props.handleSubmit}
          className={'bgvModalPrimaryBtn'}
          disabled={!isValid || isSubmitting}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
