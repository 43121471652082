import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../PrivateRoute';
import ProtectedRoute from '../ProtectedRoute';
import CommonRoute from '../CommonRoute';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import BgvHome from '../Bgv';
import CompanyAdmin from '../CompanyAdmin';
import Candidates from '../CompanyAdmin/Candidates';
import ViewCandidate from '../CompanyAdmin/ViewCandidate';
import InsufficienciesNew from '../CompanyAdmin/InsufficienciesNew';
import Profile from '../CompanyAdmin/Profile';
import Administrators from '../CompanyAdmin/Administrators';
import AdminCard from '../CompanyAdmin/Administrators/AdminCard';
import Document from '../CompanyAdmin/Document';
import BulkCandidate from '../CompanyAdmin/AddBulkCandidate';
import ExportExcel from '../CompanyAdmin/ExportExcel';
import ErrorComponent from '../../core-components/ErrorComponent';
import EmailPreferences from '../CompanyAdmin/Administrators/EmailPreferences';
import CreateCandidate from '../CompanyAdmin/CreateCandidate';
import AppConstants from '../../core-components/AppConstants';

import ViewBuyPackage from '../CompanyAdmin/ViewBuyPackage';
import PurchaseHistory from '../CompanyAdmin/Packages/Prepaid/PurchaseHistory';
import PurchaseDetails from '../CompanyAdmin/Packages/Prepaid/PurchaseHistory/PurchaseDetails';
import Credits from '../CompanyAdmin/Packages/Prepaid/Credits';
import ChargesApproval from '../CompanyAdmin/ChargesApproval';
import RenderLink from '../../core-components/RenderLink';
import RenderDocuments from '../../core-components/RenderDocuments';
import UnAuthorized from '../UnAuthorized';
import Integrations from '../CompanyAdmin/frontend-common/Integrations';
import Transactions from '../CompanyAdmin/Transactions';
import CompanySettings from '../CompanyAdmin/CompanySettings';
import securitySetting from '../../components/CompanyAdmin/frontend-common/SecuritySettings';
import LeverHome from '../CompanyAdmin/frontend-common/Integrations/Lever';
import RazorPayXHome from '../CompanyAdmin/frontend-common/Integrations/RazorPayX';
import GreenHouseHome from '../CompanyAdmin/frontend-common/Integrations/GreenHouse';
import CreditBuyPackagePage from '../../components/CompanyAdmin/frontend-common/CreditBuyPackagePage';
import Logout from '../Logout';
import PaymentRequest from '../PaymentRequest';
import WalletPackageSummary from '../CompanyAdmin/WalletPackageSummary';
import SuccessPaymentRequest from '../CompanyAdmin/frontend-common/CreditBuyPackagePage/RequestPayment/SuccessRequestPayment';
import OrderDetails from '../CompanyAdmin/Transactions/Components/OrderDetails';
import ZohoRecruit from '../CompanyAdmin/frontend-common/Integrations/ZohoRecruit';
import Recruitee from '../CompanyAdmin/frontend-common/Integrations/Recruitee';

import GoogleRedirection from '../Login/GoogleRedirection';
import MsRedirection from '../Login/MsRedirection';
import Redirect from '../Login/Redirect/Redirect';
import PlainRoute from '../PlainRoute/PlainRoute';
import Workable from '../CompanyAdmin/frontend-common/Integrations/Truto/Workable';
import Ashby from '../CompanyAdmin/frontend-common/Integrations/Truto/Ashby';
import Humaans from '../CompanyAdmin/frontend-common/Integrations/Truto/Humaans';
import HRCloud from '../CompanyAdmin/frontend-common/Integrations/Truto/HRCloud';
import ZohoPeople from '../CompanyAdmin/frontend-common/Integrations/Truto/ZohoPeople';
import Teamtailor from '../CompanyAdmin/frontend-common/Integrations/Truto/Teamtailor';
import JobScore from '../CompanyAdmin/frontend-common/Integrations/Truto/JobScore';
import Fountain from '../CompanyAdmin/frontend-common/Integrations/Truto/Fountain';
import Sprinto from '../CompanyAdmin/frontend-common/Integrations/Sprinto';
import HROne from '../CompanyAdmin/frontend-common/Integrations/HROne';

import AlexisHR from '../CompanyAdmin/frontend-common/Integrations/Truto/AlexisHR';
import BreatheHR from '../CompanyAdmin/frontend-common/Integrations/Truto/BreatheHR';
import Factorial from '../CompanyAdmin/frontend-common/Integrations/Truto/Factorial';
import HaileyHR from '../CompanyAdmin/frontend-common/Integrations/Truto/HaileyHR';
import Personio from '../CompanyAdmin/frontend-common/Integrations/Truto/Personio';
import JumpCloud from '../CompanyAdmin/frontend-common/Integrations/Truto/JumpCloud';
import HeavenHR from '../CompanyAdmin/frontend-common/Integrations/Truto/HeavenHR';
import Gusto from '../CompanyAdmin/frontend-common/Integrations/Truto/Gusto';
import PeopleHR from '../CompanyAdmin/frontend-common/Integrations/Truto/PeopleHR';
import ClayHR from '../CompanyAdmin/frontend-common/Integrations/Truto/ClayHR';
import Keka from '../CompanyAdmin/frontend-common/Integrations/Keka';
import KekaATS from '../CompanyAdmin/frontend-common/Integrations/KekaATS';

import ReferAndEarn from '../CompanyAdmin/ReferAndEarn';

import AlumniVerification from '../CompanyAdmin/AlumniVerification';
import AlumniRecordsContainer from '../CompanyAdmin/AlumniVerification/AlumniRecords';
import AddBulkExEmp from '../CompanyAdmin/frontend-common/AddBulkExEmp';
import Repute from '../CompanyAdmin/frontend-common/Integrations/Repute';
import BambooHRATS from '../CompanyAdmin/frontend-common/Integrations/KNIT/BambooHRATS';
import GreytHR from '../CompanyAdmin/frontend-common/Integrations/KNIT/GreytHR';

import ReputeComponent from '../CompanyAdmin/frontend-common/Integrations/Truto/Repute';
import JazzHR from '../CompanyAdmin/frontend-common/Integrations/Truto/JazzHR';
import SlackConnect from '../CompanyAdmin/SlackConnect/SlackConnect';
import RedirectionHandler from '../RedirectionHandler/RedirectionHandler';
import CandidateAnalytics from '../CompanyAdmin/CandidateAnalytics/CandidateAnalytics';
import ManageTagsAndCategories from '../CompanyAdmin/ManageTagsAndCategories';
import BuyRentLaptopPage from '../CompanyAdmin/UpsellServices/BuyRentLaptop/BuyRentLaptopPage';
const InternalReport = lazy(() => import('../CompanyAdmin/frontend-common/Reports/InternalReport'));

/*  
 ### NOTE ###
 AVOID CREATING ROUTES THAT START WITH v1 OR v1/* IN CA AND SA. WE'RE KEEPING THESE RESERVED FOR A NEW FRONTEND (sv-in-frontend) REPOSITORY.
*/

export default () => {
  return (
    <Suspense fallback={<div style={{ textAlign: 'center', height: '100%' }}>Loading ....</div>}>
      <Switch>
        <Route
          exact
          path={'/'}
          render={() => {
            window.open(`${AppConstants.phpURL}`, '_self');
          }}
        />
        {/* Moved Route above login, as Repute is rendering the page using Iframe */}
        <PlainRoute
          exact
          path={'/svin-repute'}
          pageTitle='Repute | SpringVerify'
          component={Repute}
          onlyAdmin={false}
        />
        <ProtectedRoute
          exact
          path={'/signIn'}
          pageTitle='Sign In | SpringVerify'
          component={Login}
        />
        <CommonRoute
          exact
          path={'/reset-password'}
          pageTitle='Reset Password | SpringVerify'
          component={ResetPassword}
        />
        <PlainRoute
          exact
          path={'/ms-redirect'}
          pageTitle='Microsoft Login | SpringVerify'
          component={MsRedirection}
        />
        <PlainRoute
          exact
          path={'/redirect'}
          pageTitle='Microsoft Login | SpringVerify'
          component={Redirect}
        />
        <PlainRoute
          exact
          path={'/google-redirect'}
          pageTitle='Google Login | SpringVerify'
          component={GoogleRedirection}
        />
        <CommonRoute
          exact
          path={'/forgot-password'}
          pageTitle='Forgot Password | SpringVerify'
          component={ForgotPassword}
        />
        <CommonRoute
          exact
          path={'/set-password'}
          pageTitle='Set Password | SpringVerify'
          component={ResetPassword}
        />
        <PlainRoute
          exact
          path={'/candidate/bgv'}
          pageTitle='BGV Form | SpringVerify'
          component={BgvHome}
        />
        <PrivateRoute
          exact
          path={'/purchase/payment-request'}
          pageTitle='Payment Request | SpringVerify'
          component={PaymentRequest}
        />
        <PrivateRoute
          exact
          path={'/purchase/payment-success'}
          pageTitle='Payment Success | SpringVerify'
          component={SuccessPaymentRequest}
        />
        <PrivateRoute
          exact
          path={'/profile'}
          pageTitle='My Profile | SpringVerify'
          component={Profile}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/dashboard'}
          pageTitle='Dashboard | SpringVerify'
          component={CompanyAdmin}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/loading'}
          pageTitle='SpringVerify'
          component={RedirectionHandler}
        />
        <PrivateRoute
          exact
          path={'/candidates'}
          pageTitle='Candidates | SpringVerify'
          component={Candidates}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/approval'}
          pageTitle='Approve Charges | SpringVerify'
          component={ChargesApproval}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/viewCandidate'}
          pageTitle='View Candidate | SpringVerify'
          component={ViewCandidate}
          onlyAdmin={false}
        />
        {/** Purchase history route is commented as per the feature ADO-3138 */}
        {/* <PrivateRoute exact path={'/purchaseHistory'} component={PurchaseHistory} onlyAdmin={false} /> */}
        <PrivateRoute
          exact
          pageTitle='Transaction Details | SpringVerify'
          path={'/transactionDetails'}
          component={PurchaseDetails}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/orderDetails'}
          pageTitle='Order Details | SpringVerify'
          component={OrderDetails}
          onlyAdmin={false}
        />
        /** Commenting out below route as per ADO-7669*/
        {/* <PrivateRoute
        exact
        path={'/insufficiencies'}
        component={InsufficienciesNew}
        onlyAdmin={false}
      /> */}
        <PlainRoute
          exact
          path={'/candidate/consent'}
          pageTitle='BGV Form | SpringVerify'
          component={BgvHome}
        />
        <PrivateRoute
          exact
          path={'/myTeam'}
          pageTitle='My Team | SpringVerify'
          component={Administrators}
          onlyAdmin={true}
        />
        /** Commenting out below routes as per ADO-7669*/
        {/* <PrivateRoute exact path={'/editUser'} component={AdminCard} onlyAdmin={true} /> */}
        {/* <PrivateRoute exact path={'/addUser'} component={AdminCard} onlyAdmin={true} /> */}
        <PrivateRoute
          exact
          path={'/manageTagsCategories'}
          pageTitle='Tags and Categories | SpringVerify'
          component={ManageTagsAndCategories}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/bulkCandidate'}
          pageTitle='Bulk Candidate | SpringVerify'
          component={BulkCandidate}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/settings'}
          pageTitle='Settings | SpringVerify'
          component={CompanySettings}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/exportCases'}
          pageTitle='Export Cases | SpringVerify'
          component={ExportExcel}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/document'}
          pageTitle='Candidate Document | SpringVerify'
          component={Document}
          onlyAdmin={false}
        />
        <PrivateRoute exact path={'/company/bgv'} component={BgvHome} onlyAdmin={false} />
        <PrivateRoute
          exact
          path={'/candidate/create'}
          pageTitle='Add Candidate | SpringVerify'
          component={CreateCandidate}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/emailPreferences'}
          pageTitle='Email Preferences | SpringVerify'
          component={EmailPreferences}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/packages'}
          pageTitle='Packages | SpringVerify'
          component={ViewBuyPackage}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/buy'}
          pageTitle='Buy | SpringVerify'
          component={CreditBuyPackagePage}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/transaction/:transactionType'}
          pageTitle='Transaction Type | SpringVerify'
          component={Transactions}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/candidateAnalytics'}
          pageTitle='Candidate Analytics | SpringVerify'
          component={CandidateAnalytics}
          env={AppConstants.env}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/transaction'}
          pageTitle='Transactions | SpringVerify'
          component={Transactions}
          onlyAdmin={true}
        />
        {/** Credit route is commented as per the feature ADO-3138 */}
        {/* <PrivateRoute exact path={'/credits'} component={Credits} onlyAdmin={true} /> */}
        {/* Needs to be improvised from the product level so commenting this for now*/}
        {/* <PrivateRoute exact path={'/invoices'} component={PostpaidInvoice} onlyAdmin={true} /> */}
        <PrivateRoute
          exact
          path={'/candidate/document'}
          pageTitle='Candidate Document | SpringVerify'
          component={RenderDocuments}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/verification-request'}
          pageTitle='Verification Consent | SpringVerify'
          component={RenderLink}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/candidate/report'}
          pageTitle='Candidate Report | SpringVerify'
          component={RenderLink}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/candidate/view/report'}
          pageTitle='Candidate Report | SpringVerify'
          component={InternalReport}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path='/candidate/resume'
          pageTitle='Candidate Resume | SpringVerify'
          component={RenderLink}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path='/invoice'
          pageTitle='Invoice | SpringVerify'
          component={RenderLink}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path='/balance'
          pageTitle='Balance | SpringVerify'
          component={WalletPackageSummary}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path='/proforma-invoice'
          pageTitle='Proforma Invoice | SpringVerify'
          component={RenderLink}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path='/logout'
          pageTitle='Logout | SpringVerify'
          component={Logout}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path='/candidate/consent-letter'
          pageTitle='Consent Letter | SpringVerify'
          component={RenderLink}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path='/refer-and-earn'
          pageTitle='Refer and Earn | SpringVerify'
          component={ReferAndEarn}
          onlyAdmin={false}
        />
        {/* Integrations */}
        <PrivateRoute
          exact
          path={'/integrations'}
          pageTitle='Integrations | SpringVerify'
          component={Integrations}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/lever'}
          pageTitle='Integration Lever | SpringVerify'
          component={LeverHome}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/razorpay'}
          pageTitle='Integration Razorpay | SpringVerify'
          component={RazorPayXHome}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/greenhouse'}
          pageTitle='Integration Greenhouse | SpringVerify'
          component={GreenHouseHome}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/zoho-recruit'}
          pageTitle='Integration Zoho Recruit | SpringVerify'
          component={ZohoRecruit}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/recruitee'}
          pageTitle='Integration Recruitee | SpringVerify'
          component={Recruitee}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/recruitee'}
          pageTitle='Integration Recruitee | SpringVerify'
          component={Recruitee}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/JazzHR'}
          pageTitle='Integration JazzHR | SpringVerify'
          component={JazzHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/BambooHRATS'}
          pageTitle='Integration BambooHR(ATS) | SpringVerify'
          component={BambooHRATS}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/GreytHR'}
          pageTitle='Integration GreytHR | SpringVerify'
          component={GreytHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path='/slack-connect'
          pageTitle='Slack Connect | SpringVerify'
          component={SlackConnect}
        />
        <PrivateRoute
          exact
          path='/alumni-verification'
          pageTitle='Alumni Verification | SpringVerify'
          component={AlumniVerification}
        />
        <PrivateRoute
          exact
          path='/buy-rent-laptop'
          pageTitle='Buy Laptop | SpringVerify'
          component={BuyRentLaptopPage}
        />
        <PrivateRoute
          exact
          path='/alumni-verification/record'
          pageTitle='Alumni Verification | SpringVerify'
          component={AlumniRecordsContainer}
        />
        <PrivateRoute
          exact
          path={'/alumni-verification/bulk-upload'}
          pageTitle='Alumni Verification | SpringVerify'
          component={AddBulkExEmp}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/integrations/workable'}
          pageTitle='Integration Workable | SpringVerify'
          component={Workable}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/ashby'}
          pageTitle='Integration Ashby | SpringVerify'
          component={Ashby}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/humaans'}
          pageTitle='Integration Humaans | SpringVerify'
          component={Humaans}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/hrcloud'}
          pageTitle='Integration HR Cloud | SpringVerify'
          component={HRCloud}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/repute'}
          pageTitle='Integration Repute | SpringVerify'
          component={ReputeComponent}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/teamtailor'}
          pageTitle='Integration Teamtailor | SpringVerify'
          component={Teamtailor}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/jobscore'}
          pageTitle='Integration JobScore | SpringVerify'
          component={JobScore}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/fountain'}
          pageTitle='Integration Fountain | SpringVerify'
          component={Fountain}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/zohopeople'}
          pageTitle='Integration Zoho People | SpringVerify'
          component={ZohoPeople}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/sprinto'}
          pageTitle='Integration Sprinto | SpringVerify'
          component={Sprinto}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/hrone'}
          pageTitle='Integration HROne | SpringVerify'
          component={HROne}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/alexishr'}
          pageTitle='Integration AlexisHR | SpringVerify'
          component={AlexisHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/breathehr'}
          pageTitle='Integration BreatheHR | SpringVerify'
          component={BreatheHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/factorial'}
          pageTitle='Integration Factorial | SpringVerify'
          component={Factorial}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/haileyhr'}
          pageTitle='Integration HaileyHR | SpringVerify'
          component={HaileyHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/personio'}
          pageTitle='Integration Personio | SpringVerify'
          component={Personio}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/jumpcloud'}
          pageTitle='Integration JumpCloud | SpringVerify'
          component={JumpCloud}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/heavenhr'}
          pageTitle='Integration HeavenHR | SpringVerify'
          component={HeavenHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/gusto'}
          pageTitle='Integration Gusto | SpringVerify'
          component={Gusto}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/peoplehr'}
          pageTitle='Integration PeopleHR | SpringVerify'
          component={PeopleHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/clayhr'}
          pageTitle='Integration ClayHR | SpringVerify'
          component={ClayHR}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/keka'}
          pageTitle='Integration Keka | SpringVerify'
          component={Keka}
          onlyAdmin={true}
        />
        <PrivateRoute
          exact
          path={'/integrations/keka-ats'}
          pageTitle='Integration Keka | SpringVerify'
          component={KekaATS}
          onlyAdmin={true}
        />
        {/* ------ */}
        <PlainRoute
          exact
          path={'/error'}
          pageTitle='Error | SpringVerify'
          component={ErrorComponent}
          onlyAdmin={false}
        />
        <PrivateRoute
          exact
          path={'/unauthorized'}
          pageTitle='Unauthorized | SpringVerify'
          component={UnAuthorized}
          onlyAdmin={false}
        />
        <ProtectedRoute path='*' component={Login} noMatchFound={true} />
        <PrivateRoute
          exact
          path='/company/security-settings'
          pageTitle='Security Settings | SpringVerify'
          component={securitySetting}
        />
        {/*  ### NOTE ### 
              AVOID CREATING ROUTES THAT START WITH v1 OR v1/* IN CA AND SA. WE'RE KEEPING
              THESE RESERVED FOR A NEW FRONTEND (sv-in-frontend) REPOSITORY.  
        */}
      </Switch>
    </Suspense>
  );
};
