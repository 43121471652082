import {
  faIdCard,
  faGavel,
  faMapMarkerAlt,
  faBriefcase,
  faGraduationCap,
  faDatabase,
  faPrescriptionBottleAlt,
  faCreditCard,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

export const IdentityArr = {
  PAN: 'PAN',
  DL: 'Driving License',
  VOTER_ID: 'Voter ID',
  AADHAAR: 'Aadhaar',
  PASSPORT: 'Passport'
};

export const AddressArr = {
  'Current Address': 'Current Address',
  'Permanent Address': 'Permanent Address',
  CURRENT: 'Current Address',
  PERMANENT: 'Permanent Address',
  ANY_1: 'Any 1 (PERMANENT,CURRENT)',
  ANY_2: '(PERMANENT,CURRENT)'
};
export const AddressArrLabelObj = {
  'Current Address': 'CURRENT',
  'Permanent Address': 'PERMANENT',
  CURRENT: 'CURRENT',
  PERMANENT: 'PERMANENT',
  'Any Address': 'ANY_1'
};

export const AddressConstants = {
  ANY_1: 'ANY_1',
  ANY_2: 'ANY_2',
  PERMANENT: 'PERMANENT',
  CURRENT: 'CURRENT'
};

export const AddressObject = {
  'Permanent Address': 'PERMANENT',
  'Current Address': 'CURRENT',
  permanent_address: 'PERMANENT',
  current_address: 'CURRENT'
};

export const EducationArr = {
  10: 'Tenth',
  12: 'Twelfth / Diploma',
  UG: 'Graduate',
  UNDERGRAD: 'Graduate',
  PG: 'Post Graduation',
  POSTGRAD: 'Post Graduation',
  PHD: 'PhD',
  NONE: 'NONE',
  NO_EDUCATION: 'No Education',
  LESS_THEN_FIFTH_STD: 'Less then fifth std',
  FIFTH_STD: 'Fifth std',
  MASTERS: 'Masters',
  POST_DOC: 'Post Doc',
  POST_GRADUATE_DIPLOMA: 'Post graduate diploma',
  OTHER: 'Other',
  UNIVERSITY: 'University',
  HIGH_SCHOOL: 'High school',
  PROFESSIONAL_COURSE: 'Professional course'
};

export const obj = {
  10: 'Tenth',
  12: 'Twelfth / Diploma',
  '12/Diploma': 'Twelfth / Diploma',
  UNDERGRAD: 'Graduate',
  POSTGRAD: 'Post Graduation',
  'Twelfth/Diploma': 'Twelfth / Diploma',
  Postgraduate: 'Post Graduation',
  UG: 'Graduate',
  PG: 'Post Graduation',
  PHD: 'PhD',
  '10th': 'Tenth',
  '12th': 'Twelfth / Diploma',
  Graduation: 'Graduate',
  'Post Graduation': 'Post Graduation',
  Doctorate: 'Doctorate',
  Diploma: 'Diploma',
  Other: 'Other',
  Undergraduate: 'Graduate'
};

export const IDENTITES_FOR_PACKAGE_CONFIG = [
  { name: 'PAN', label: 'PAN' },
  { name: 'AADHAAR', label: 'Aadhaar' },
  { name: 'DL', label: 'Driving License' },
  { name: 'VOTER_ID', label: 'Voter ID' },
  { name: 'PASSPORT', label: 'Passport' }
];

export const ADDRESS_FOR_PACKAGE_CONFIG = [
  { name: 'ANY_1', label: 'Any 1 address' },
  { name: 'CURRENT', label: 'Current address' },
  { name: 'PERMANENT', label: 'Permanent address' }
];

export const PLAIN_CHECK_ICONS = {
  identity: { icon: faIdCard, title: 'Identity Verification' },
  court: { icon: faGavel, title: 'Court Record' },
  address: { icon: faMapMarkerAlt, title: 'Address Verification' },
  employment: { icon: faBriefcase, title: 'Employment Verification' },
  education: { icon: faGraduationCap, title: 'Education Verification' },
  education_official: { icon: faGraduationCap, title: 'Education Verification' },
  education_regional_partner: { icon: faGraduationCap, title: 'Education Verification' },
  education_hybrid: { icon: faGraduationCap, title: 'Education Verification' },
  world: { icon: faDatabase, title: 'World Check' },
  drug: { icon: faPrescriptionBottleAlt, title: 'Drug Check' },
  credit: { icon: faCreditCard, title: 'Cibil check' },
  reference: { icon: faUsers, title: 'Reference' }
};

export const ADDRESS_LOOPING_CONSTANT = [
  { label: 'Permanent Address', value: 'permanent_address', dataName: 'PERMANENT' },
  { label: 'Current Address', value: 'current_address', dataName: 'CURRENT' },
  { label: 'Any Address', value: 'any_address', dataName: 'ANY ADDRESS' }
];

export const DRUG_CHECK_TEXT = {
  '5_PANEL': '5 Panel',
  '7_PANEL': '7 Panel',
  '10_PANEL': '10 Panel'
};

export const EDUCATION_TYPES_FOR_PACKAGE_CONFIG = [
  { name: '10', label: 'Tenth' },
  { name: '12', label: 'Twelfth / Diploma' },
  { name: 'UNDERGRAD', label: 'Graduate' },
  { name: 'POSTGRAD', label: 'Post Graduation' },
  { name: 'PHD', label: 'PhD' }
];

export const ADDRESS_FOR_ADDON_CONFIG = [
  { name: 'CURRENT', label: 'Current address' },
  { name: 'PERMANENT', label: 'Permanent address' }
];

export const ADDRESS_VERIFICATION_TYPES = [
  { name: 'ADDRESS_DIGITAL', label: 'Digital' },
  { name: 'ADDRESS_PHYSICAL', label: 'Physical' },
  { name: 'ADDRESS_POSTAL', label: 'Postal' }
];
export const COURT_VERIFICATION_TYPES = [
  { name: 'COURT_STANDARD', label: 'Standard' },
  { name: 'COURT_STANDARD_LAWFIRM', label: 'Standard via Lawfirm' }
];
export const COURT_OPTIONS_FOR_ADDON_CONFIG = [
  { name: 'CURRENT', label: 'Current address', id: 'court_current_add' },
  { name: 'PERMANENT', label: 'Permanent address', id: 'court_permanent_add' }
];

export const ID_DROPDOWN = {
  PAN: { label: 'PAN', value: 'ind_pan', name: 'PAN' },
  DL: { label: 'Driving License', value: 'ind_driving_license', name: 'DL' },
  PASSPORT: { label: 'Passport', value: 'ind_passport', name: 'PASSPORT' },
  VOTER_ID: { label: 'Voter ID', value: 'ind_voter_id', name: 'VOTER_ID' },
  AADHAAR: { label: 'Aadhaar', value: 'ind_aadhaar', name: 'AADHAAR' }
};

export const ID_DOC_TYPE = {
  PAN: 'pan',
  DL: 'driving_license',
  PASSPORT: 'passport',
  VOTER_ID: 'voter_id',
  AADHAAR: 'aadhaar'
};

export const DOC_TYPE_MAPPING = {
  ind_aadhaar: 'aadhaar',
  ind_passport: 'passport',
  ind_pan: 'pan',
  ind_voter_id: 'voterId',
  ind_driving_license: 'drivingLicense'
};

export const MAP_OLD_ADDRESS_DOC_TYPE = {
  Aadhaar: 'Aadhaar (Front And Back)',
  'Driving License': `Driving's License (Front And Back)`,
  Passport: 'Passport (First And Second Page)',
  'Voter ID': 'Voter ID (Front And Back)',
  Other: 'Other'
};

export const ADDRESS_DOC_TYPE = [
  { label: 'Active Rental Agreement', value: 'rental_agreement' },
  { label: 'Sale Deed/Agreement', value: 'sale_deed' },
  { label: 'Gas Bill (Recent 3 Months)', value: 'gas_bill' },
  { label: 'Electricity Bill (Recent 3 Months)', value: 'electricity_bill' },
  { label: 'Internet Bill (Recent 3 Months)', value: 'internet_bill' },
  { label: 'Telephone Bill (Recent 3 Months)', value: 'telephone_bill' },
  { label: 'Water Bill (Recent 3 Months)', value: 'water_bill' },
  { label: 'Aadhaar (Front And Back)', value: 'aadhaar' },
  { label: `Driving's License (Front And Back)`, value: 'driving_license' },
  { label: 'Voter ID (Front And Back)', value: 'voter_id' },
  { label: 'Passport (First And Second Page)', value: 'passport' },
  { label: 'Ration Card', value: 'ration_card' },
  { label: 'Bank Passbook (Front Page With Seal)', value: 'bank_passbook' },
  { label: 'Credit Card Statement', value: 'credit_card_statement' },
  { label: 'Vehicle Registration Certificate', value: 'vehicle_registration_certificate' },
  { label: 'Society Maintenance Bill', value: 'society_maintenance_bill' },
  { label: 'Rent Receipts', value: 'rent_receipts' },
  { label: 'Affidavit (Only If None Of The Above Is Available)', value: 'affidavit' },
  { label: 'Other', value: 'others' }
];

export const EDUCATION_TYPES = [
  { name: 'OFFICIAL', label: 'Official Verification' },
  { name: 'REGIONAL_PARTNER', label: 'Regional Partner Verification' },
  { name: 'HYBRID', label: 'Hybrid Verification' }
];

export const EDUCATION_TYPES_ADDONS = {
  OFFICIAL: 'education',
  REGIONAL_PARTNER: 'educationThirdParty',
  HYBRID: 'educationHybrid'
};
export const EDUCATION_TYPES_ADDONS_BY_ADDONS = {
  education: 'OFFICIAL',
  educationThirdParty: 'REGIONAL_PARTNER',
  educationHybrid: 'HYBRID'
};

export const APPROVAL_STATUS = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Approved & Auto-approved', value: 'APPROVED' },
  { label: 'Rejected & Auto-rejected', value: 'REJECTED' },
  { label: 'Deleted & Refunded', value: 'DELETED' }
];
export const COST_TYPE = [
  { label: 'All', value: '' },
  { label: 'Pass-through fee', value: 'PASS_THROUGH' },
  { label: 'International charge', value: 'INTERNATIONAL' },
  { label: 'Re-initiation charge', value: 'RE_INITIATION' },
  { label: 'Other charge', value: 'OTHER' }
];
export const CHECKS_LIMIT = {
  ID: 5,
  ADD: 2,
  COURT: 2,
  EMP: 10,
  DRUG: 1,
  EDU: 5,
  REF: 10,
  WORLD: 1,
  CIBIL: 1
};

export const GENDER = {
  1: 'Male',
  2: 'Female',
  3: 'Other'
};

export const loggly_payment_type = {
  ADDON: 'ADDON BOUGHT',
  ADC_RECHARGE: 'ADC RECHARGE',
  CREDITS: 'CREDITS BOUGHT',
  CREATE_CANDIDATE: 'CANDIDATE ADDED',
  PACKAGE_BOUGHT: 'PACKAGE BOUGHT',
  ORDER: 'ORDER'
};

export const CANDIDATES_STATUSES = [
  { label: 'In Progress', value: 0 },
  { label: 'Completed', value: 1 },
  { label: 'Awaiting Input', value: 3 },
  { label: 'Processing', value: 4 },
  { label: 'Completed with exception', value: 6 },
  { label: 'Insufficiency', value: 7 },
  { label: 'Closed', value: 8 },
  { label: 'On Hold (Checks)', value: 9 },
  { label: 'Consent Missing', value: 11 },
  { label: 'Insufficient Funds', value: 12 }
];
export const FORM_PENDING_STATUS = [
  { label: 'Awaiting Input', value: 3 },
  { label: 'Consent Missing', value: 11 }
];
export const IN_PROGRESS_STATUS = [{ label: 'In Progress', value: 0 }];
export const INSUFFICIENCY_STATUS = [{ label: 'Insufficiency', value: 7 }];
export const ONHOLD_STATUS = [{ label: 'On Hold (Checks)', value: 9 }];
export const COMPLETE_STATUS = [
  { label: 'Completed', value: 1 },
  { label: 'Completed with exception', value: 6 },
  { label: 'Closed', value: 8 }
];

export const CANDIDATES_TIME_RANGE = [
  { label: 'Select', value: -1 },
  { label: 'All time', value: 1 },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 60 days', value: 60 },
  { label: 'Last 90 days', value: 90 },
  { label: 'Custom range', value: 0 }
];

export const DIGEST_FREQUENCY = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' }
];

export const DIGEST_TYPE = [
  { label: 'Cases completed', value: 'CANDIDATE' },
  { label: 'Checks completed', value: 'CHECK' }
];

export const IDENTITY_TYPE = {
  1: 'Any',
  2: 'PAN',
  3: 'Driving License',
  4: 'Passport',
  5: 'Voter ID',
  6: 'Aadhaar'
};

export const ICONS_VERIFICATIONS = [
  { value: 'Identity Verification', icon: 'Identity', width: 27 },
  { value: 'Employment Verification', icon: 'Employment', width: 27 },
  { value: 'Address Verification', icon: 'Address', width: 27 },
  { value: 'Education Verification', icon: 'Education', width: 27 },
  { value: 'World Verification', icon: 'World', width: 27 },
  { value: 'Drug Verification', icon: 'Drug', width: 27 },
  { value: 'Court Record Verification', icon: 'Court_Record', width: 27 },
  { value: 'Credit Check', icon: 'CIBIL_Credit_Score', width: 27 },
  { value: 'Reference Verification', icon: 'Reference', width: 27 },
  { value: 'Custom Verification', icon: 'Custom', width: 27 }
];

//TDS options for comany
export const TDS_OPTIONS = [
  { value: 'NONE', label: 'None', disable: false },
  { value: '2', label: '2%', disable: false },
  { value: '10', label: '10%', disable: false }
];

// TDS tooltip msg
export const TDS_TOOLTIP =
  'Tax Deduction at Source (TDS) is the amount of tax that is deducted from the taxpayer and is deposited to the Income Tax Department on behalf of the taxpayer.';

export const PACKAGE_UNIT_INDICATION = {
  NILL: 'NILL', // CRITICAL
  LOW: 'LOW',
  SUFFICIENT: 'SUFFICIENT'
};

export const WALLET_BALLANCE_INDICATION = {
  NILL: 'NILL', // CRITICAL
  LOW: 'LOW',
  SUFFICIENT: 'SUFFICIENT'
};

export const PACKAGE_UNIT_INDICATION_FACTOR = 0.2;
export const PASSWORD_CRITERIA = [
  'minimum 12 characters',
  'one UPPER CASE character',
  'one lower case character',
  'one number',
  'one symbol',
  'no successively repeating characters'
];

export const PHONE_NUMBER_SAMPLE = '9023402946';
export const FILLED_CHECKS = {
  identity: 'candidatesIds',
  address: 'candidatesAddresses',
  employment: 'candidatesEmployments',
  education: 'candidatesEducations',
  court: 'candidateHistories',
  world: 'candidatesWorldchecks',
  drug: 'candidateDrugs',
  reference: 'candidatesRefchecks',
  cibil: 'candidatesCreditchecks'
};

export const TRANSACTION_TYPE = {
  PURCHASE: 'Purchase (Recharge)',
  RECHARGE: 'Purchase (Recharge)',
  PACKAGE_BOUGHT: 'Purchase (Recharge)',
  FREE_CREDITS: 'Free Credits',
  FREE_CREDITS_TRIAL: 'Free Credits (Trial)',
  CREDIT_REFUND: 'Refund to Customer'
};
export const USAGE_EVENTS = {
  RECHARGE: 'Purchage (Recharge)',
  PACKAGE_BOUGHT: 'Package',
  CREDIT_NOTE: 'Credit Note',
  CREDIT_REFUND: 'Refund to Customer',
  FREE_CREDITS: 'Free Credits',
  CREDIT_DEDUCTION: 'Credit Adjustment',
  SPLIT_PURCHASE: 'Split Purchase',
  PACKAGE_UNITS_CONVERTED_TO_CREDITS: 'Package Refund',
  BRIDGE_CREDITS: 'Bridge credits',
  FREE_CREDITS_TRIAL: 'Free credits (Trial)',
  CREDIT_DEDUCTION_BRIDGE_CREDITS: 'Bridge Credits Deduction',
  CANDIDATE_ADDED: 'Candidate added',
  CANDIDATE_DELETED: 'Candidate deleted',
  DISCOUNT: 'Discount',
  PENALTY: 'Penalty',
  TRIAL: 'Trial',
  INVOICE_PAID: 'Invoice paid',
  CREDIT_LIMIT_INCREASED: 'Credit limit increased',
  CREDIT_LIMIT_DECREASED: 'Credit limit decreased',
  ADDON_BOUGHT: 'Add-on Check',
  CHECK_REFUND: 'Add-on Check Refund',
  ADDITIONAL_CHARGE: 'Additional Cost',
  ADDITIONAL_CHARGE_REFUND: 'Additional Cost Refund',
  CREDITS_LOCKED: '- Credits Locked',
  CREDITS_UNLOCKED: '+ Credits Unlocked',
  CHECK_VERIFICATION_TYPE_CONVERSION: 'Verification Type Convert',
  ALUMNI_VERIFICATION_BONUS: 'Alumni Verification Bonus'
};

export const ADC_TYPES = {
  PASS_THROUGH: 'Pass Through',
  INTERNATIONAL: 'International',
  RE_INITIATION: 'Re-initiation',
  OTHER: 'Other'
};
export const PAYMENT_MODE_OBJ = {
  CASH: 'Cash',
  CREDIT_CARD: 'Credit / Debit card',
  DEMAND_DRAFT: 'Demand Draft',
  CHEQUE: 'Cheque',
  NEFT_OR_UPI: 'Bank Transfer',
  PAYMENT_GATEWAY: 'Payment Gateway'
};
export const sexTypes = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Non-binary' },
  { value: 4, label: 'Prefer not to disclose' }
];
export const sexTypesObj = {
  1: 'Male',
  2: 'Female',
  3: 'Non-binary',
  4: 'Prefer not to disclose'
};

export const modelType = {
  prepaid: 'PREPAID',
  postpaid: 'POSTPAID_CANDIDATE_COMPLETION'
};
// Password rules used in set/reset/forgot password flow.
export const PASSWORD_RULES = {
  IS_CHARACTER_RULE_SATISFIED: false,
  IS_AT_LEAST_ONE_UPPER_CASE_PRESENT: false,
  IS_AT_LEAST_ONE_LOWER_CASE_PRESENT: false,
  IS_NUMBER_RULE_SATISFIED: false,
  IS_SYMBOL_RULE_SATISFIED: false,
  IS_NO_REPEATING_CHAR_PRESENT: false
};

// Missing country codes
export const COUNTRY_CODES = {
  'United Kingdom of Great Britain and Northern Ireland': 'GB',
  'United States of America': 'US'
};

// Additional Charge Constants  //
export const ADC_ALWAYS_APPROVE = 'ALWAYS_APPROVE';
export const ADC_APPROVE_TILL_LIMIT = 'APPROVE_TILL_LIMIT';
export const ADC_APPROVAL_REQUIRED = 'APPROVAL_REQUIRED';
export const ADC_ALWAYS_REJECT = 'ALWAYS_REJECT';
export const ADC_CUSTOM_AMOUNT = 'CUSTOM_AMOUNT';
export const ADC_CUSTOM_CONFIG = 'CUSTOM_CONFIG';

// Additional Charges Aut0-approval Amount dropdown options
export const AUTO_APPROVAL_AMOUNT = [
  { value: 1000, label: '1,000' },
  { value: 2500, label: '2,500 [RECOMMENDED]' },
  { value: 5000, label: '5,000' },
  { value: ADC_CUSTOM_AMOUNT, label: 'Enter custom amount' },
  { value: ADC_ALWAYS_APPROVE, label: 'Approve any amount' }
];

// ID configs - Use it for comparison with API response
export const CONFIG_ID_TYPES = {
  AADHAAR: 'AADHAAR',
  PAN: 'PAN',
  PASSPORT: 'PASSPORT',
  VOTER_ID: 'VOTER_ID',
  DL: 'DL',
  ANY: 'ANY'
};

// Settings Page Tabs
export const TAB_BRAND = 'Company Profile';
export const TAB_COMMUNICATION = 'Communication';
export const TAB_ADDITIONAL_CHARGES = 'Additional Charges';
export const TAB_SECURITY = 'Security';
export const TAB_API = 'API';
export const TAB_ALUMNI_VERIFICATION = 'Alumni Verification';
export const TAB_TAG = 'Tags';
export const TAB_CATEGORY = 'Categories';

// Tabs Mapping for Query Params
export const DEFAULT_TAB_MAPPING = {
  bnd: TAB_BRAND,
  adc: TAB_ADDITIONAL_CHARGES,
  sec: TAB_SECURITY,
  api: TAB_API,
  com: TAB_COMMUNICATION,
  avt: TAB_ALUMNI_VERIFICATION,
  tag: TAB_TAG,
  category: TAB_CATEGORY
};

export const PAYMENT_MODEL_TYPE = {
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID'
};
export const ACCESS_LEVEL = {
  COMPANY_ADMIN: 5
};

export const PAYMENT_REQUEST_EXPIRY_IN_DAYS = 30;

export const CANDIDATE_STATUS = {
  IN_PROGRESS: 0,
  COMPLETED: 1,
  AWAITING_INPUT: 3,
  PROCESSING: 4,
  COMPLETED_WITH_EXCEPTION: 6,
  INSUFFICIENCY: 7,
  CLOSED: 8,
  ON_HOLD: 9,
  CONSENT_MISSING: 11,
  INSUFFICIENT_FUNDS: 12
};

export const EXPIRED_PASSWORD_ERROR_TEXT = `Your current password has expired. We have sent you an email to reset your password. In case you have not received the email, try \"Forgot password\".`;
export const EXPIRED_PASSWORD_ERROR_TEXT_LOGIN = `Your current password has expired. Use \"Forgot password\" to set a new password.`;
export const PASSWORD_NOT_SET_ERROR_TEXT = `Password has not been set for this account. We have sent you an email to set your password. In case you have not received the email, try "Forgot password".`;
export const INACTIVE_USER_ERROR_TEXT = `We couldn't find an active user with email`;

export const BGV_ERROR = {
  UNAUTHORIZED: 'unauthorized',
  ERROR: 'error'
};
export const educationVerificationMediumObject = {
  OFFICIAL: 'Official',
  REGIONAL_PARTNER: 'Regional Partner',
  HYBRID: 'Hybrid'
};

export const CA_REFERRAL_AMOUNT = 2500;
// ADC Edu charge types
export const EDU_CHARGE_TYPE = {
  UNIVERSITY: 'UNIVERSITY',
  INSTITUTE: 'INSTITUTE'
};

/**
 * Used in Bulk Upload - Alumni Verification modal
 */
export const UPLOAD_STATE = {
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  NOT_UPLOADED: 'NOT_UPLOADED',
  FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  NETWORK_ERROR: 'NETWORK_ERROR'
};
// Upsell modal Check icon file name mapping
export const UPSELL_MODAL_CHECK_ICON = {
  DRUG: 'DRUG.svg',
  DIRECTORSHIP: `DIRECTORSHIP.svg`,
  ECONOMIC: `ECONOMIC.svg`,
  DRUG_10_PANEL: 'DRUG.svg',
  DRUG_5_PANEL: 'DRUG.svg',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA.svg',
  PASSPORT: 'PASSPORT.svg',
  COURT_PERMANENT: 'COURT.svg',
  COURT_CURRENT: 'COURT.svg',
  EMPLOYMENT: 'EMPLOYMENT.svg',
  EMPLOYMENT_GAP: 'EMPLOYMENT_GAP.svg',
  PAN: 'PAN.svg',
  RESUME: 'RESUME.svg',
  UAN: 'UAN.svg',
  REFERENCE: 'REFERENCE.svg',
  WORLD: 'WORLD.svg',
  EDUCATION: 'EDUCATION.svg',
  EDUCATION_OFFICIAL: 'EDUCATION.svg',
  EDUCATION_HYBRID: 'EDUCATION.svg',
  EDUCATION_REGIONAL: 'EDUCATION.svg',
  ADDRESS_POSTAL: 'POSTAL_ADD.svg',
  ADDRESS_CURRENT: 'CUR_ADD.svg',
  ADDRESS_PERMANENT: 'PER_ADD.svg',
  POSTAL: 'POSTAL.svg',
  CREDIT: 'CREDIT.svg'
};

// Upsell modal check info text
export const UPSELL_MODAL_CHECK_INFO_TEXT = {
  CREDIT:
    'Get informed about addresses associated with candidates in addition to their primary residency.',
  DRUG: 'Help your company stay compliant and meet work policy requirements by screening pre/post hires.',
  DRUG_10_PANEL:
    'Help your company stay compliant and meet work policy requirements by screening pre/post hires.',
  DRUG_5_PANEL:
    'Help your company stay compliant and meet work policy requirements by screening pre/post hires.',
  SOCIAL_MEDIA: 'Screen their online behaviour to ensure it aligns to your company’s values.',
  PASSPORT: `Verify if the candidate is who they claim they are and have rights to work at your organisation.`,
  COURT_PERMANENT: `Know if your candidate has been involved in serious or organized crimes.`,
  COURT_CURRENT: `Know if your candidate has been involved in serious or organized crimes.`,
  EMPLOYMENT: `Hire with confidence by verifying your candidate's employment history, title, duration, and more.`,
  EMPLOYMENT_GAP: `Get the right reasons for gaps in work experiences to ensure it's not a cause of concern for you.`,
  PAN: 'Make sure your potential employee has a healthy financial and tax submission record.',
  RESUME: 'Evaluate if resume details match the actual details of your candidates.',
  UAN: 'Fetch the service history instantly and get informed of employee moonlighting.',
  REFERENCE: 'Understand your candidates better from the perspective of their ex-managers/peers.',
  WORLD:
    'Stay aware of potential threats by Identifying if a candidate is charged with legal or criminal lawsuits.',
  EDUCATION_HYBRID: `Make informed hiring decisions by confirming your candidate's education details with this highest quality of
Education check.`,
  EDUCATION_REGIONAL: `Make informed hiring decisions by confirming your candidate's education details with this highest quality of
Education check.`,
  EDUCATION: `Make informed hiring decisions by confirming your candidate's education credentials, degree, professional license, certification, and more.`,
  EDUCATION_OFFICIAL: `Make informed hiring decisions by confirming your candidate's education credentials, degree, professional license, certification, and more.`,
  ADDRESS_POSTAL:
    'An additional layer of address verification in case for any discrepancy with current mode of verification.',
  ADDRESS_CURRENT: `Verify the current place of residence to ensure you don't hire applicants with wrong location credentials.`,
  ADDRESS_PERMANENT: `Verify the permanent place of residence to ensure you don't hire applicants with wrong location credentials.`,
  POSTAL:
    'An additional layer of address verification in case for any discrepancy with current mode of verification.',
  ECONOMIC: `Verification includes checking SFIO, NSE, BSE databases, and credit reports.`,
  DIRECTORSHIP: `Verification confirms the candidate's Directorship with Indian companies via databases.`
};

// Upsell modal check name mapping
export const UPSELL_MODAL_CHECK_NAME = {
  CREDIT: { fullName: 'Credit', shortName: 'Credit' },
  POSTAL: { fullName: 'Postal Address Verification', shortName: 'Postal Address' },
  ADDRESS_PERMANENT: { fullName: 'Permanent Address Validation', shortName: 'Permanent Address' },
  ADDRESS_CURRENT: { fullName: 'Current Address Validation', shortName: 'Current Address' },
  ADDRESS_POSTAL: { fullName: 'Postal address verification', shortName: 'Postal Address' },
  EDUCATION: { fullName: 'Education Check', shortName: 'Education Check' },
  EDUCATION_OFFICIAL: { fullName: 'Education (Official)', shortName: 'Education (Official)' },
  EDUCATION_HYBRID: {
    fullName: 'Education Check (UPGRADE TO HYBRID MODE)',
    shortName: 'Education (Hybrid)'
  },
  EDUCATION_REGIONAL: {
    fullName: 'Education Check (UPGRADE TO REGIONAL MODE)',
    shortName: 'Education (Regional)'
  },
  WORLD: { fullName: 'World Check', shortName: 'World Check' },
  REFERENCE: { fullName: 'Reference Verification', shortName: 'Reference Check' },
  UAN: { fullName: 'UAN', shortName: 'UAN' },
  RESUME: { fullName: 'Resume Check', shortName: 'Resume Review' },
  PAN: { fullName: 'PAN', shortName: 'PAN' },
  EMPLOYMENT: { fullName: 'Employment', shortName: 'Employment' },
  EMPLOYMENT_GAP: { fullName: 'Employment Gap', shortName: 'Employment Gap' },
  COURT_PERMANENT: { fullName: 'Court Check (Permanent address)', shortName: 'Court Check' },
  COURT_CURRENT: { fullName: 'Court Check (Current address)', shortName: 'Court Check' },
  PASSPORT: { fullName: 'Passport', shortName: 'Passport' },
  SOCIAL_MEDIA: { fullName: 'Social Media', shortName: 'Social Media' },
  DRUG: { fullName: 'Drug Test', shortName: 'Drug Test' },
  DRUG_10_PANEL: { fullName: 'Drug Check (10 Panel)', shortName: 'Drug Test' },
  DRUG_5_PANEL: { fullName: 'Drug Check (5 Panel)', shortName: 'Drug Test' },
  ECONOMIC: {
    fullName: 'Economic Default Verification',
    shortName: 'Economic Default Verification'
  },
  DIRECTORSHIP: {
    fullName: 'Directorship  Verification',
    shortName: 'Directorship Verification'
  }
};

export const KBA_URL = `https://support.springworks.in/portal/en/kb/articles/all-the-checks-you-need-are-in-one-place-26-3-2024`;

export const REFER_SOURCE = [
  { label: 'Worked at same organization', value: 'WORKED_AT_SAME_ORGANIZATION' },
  { label: 'Connected over a job opportunity', value: 'CONNECTED_OVER_A_JOB_OPPORTUNITY' },
  { label: 'Friend/Acquaintance', value: 'FRIEND_ACQUAINTANCE' },
  { label: 'Other', value: 'OTHER' }
];
export const empDropdownArray = [
  { label: 'Offer Letter', value: 'offer_letter' },
  { label: 'Appointment Letter', value: 'appointment_letter' },
  { label: 'Payslips (Any Month)', value: 'payslip' },
  { label: 'Resignation Acceptance Letter', value: 'resignation_acceptance_letter' },
  { label: 'Relieving Letter', value: 'relieving_letter' },
  { label: 'Service/Experience Letter', value: 'experience_letter' },
  { label: 'Full And Final Settlement Document', value: 'full_and_final_settlement_document' },
  { label: 'Internship Experience Letter', value: 'internship_experience_letter' },
  { label: 'Internship Offer Letter', value: 'internship_offer_letter' },
  { label: 'Employment Contract Or Agreement', value: 'employment_agreement' },
  { label: 'Contract Completion', value: 'contract_completion' },
  { label: 'Termination Letter', value: 'termination_letter' }
];

export const DELETE_REASON = [
  {
    label: 'Other',
    value: 'OTHER'
  },
  {
    label: 'Wrong amount',
    value: 'WRONG_AMOUNT'
  },
  {
    label: 'Change in amount',
    value: 'CHANGE_IN_AMOUNT'
  },
  {
    label: `Checks closed as per client's request`,
    value: 'CLIENT_REQUEST'
  },
  {
    label: 'Required doc/data insufficiency not cleared',
    value: 'DATA_INSUFFICIENCY'
  },
  {
    label: 'The check was closed by SA',
    value: 'CLOSED_BY_SA'
  }
];

export const EMP_REASONS_FOR_LEAVING = [
  {
    label: 'Resignation',
    value: 'RESIGNATION'
  },
  {
    label: 'Better Opportunity',
    value: 'BETTER_OPPORTUNITY'
  },
  {
    label: 'Career Growth',
    value: 'CAREER_GROWTH'
  },
  {
    label: 'Personal Reasons',
    value: 'PERSONAL_REASONS'
  },
  {
    label: 'Pursuing Higher Education',
    value: 'PURSUING_HIGHER_EDUCATION'
  },
  {
    label: 'Company Downsizing/Restructuring',
    value: 'COMPANY_DOWNSIZING/RESTRUCTURING'
  },
  {
    label: 'Termination',
    value: 'TERMINATION'
  },
  {
    label: 'Other',
    value: 'OTHER'
  }
];

export const USER_TYPES = {
  ADMIN: 'admin',
  HR: 'hr'
};

export const TOKEN_TYPE = {
  COMPANY: 'COMPANY'
};

export const HOLD_CHECK_REASON = [
  {
    label: 'Info / Doc Unavailable',
    value: 'INFO_OR_DOC_UNAVAILABLE'
  },
  { label: 'Other', value: 'OTHER' }
];

export const EDU_CHECK_HOLD_REASON = [
  {
    label: 'Currently studying',
    value: 'CURRENTLY_STUDYING'
  },
  {
    label: 'Awaiting results',
    value: 'AWAITING_RESULTS'
  },
  {
    label: 'Completed, yet to be issued docs',
    value: 'COMPLETED_BUT_DOCS_NOT_ISSUED'
  },
  {
    label: 'Info / Doc Unavailable',
    value: 'INFO_OR_DOC_UNAVAILABLE'
  },
  { label: 'Other', value: 'OTHER' }
];

export const EMP_CHECK_HOLD_REASON = [
  {
    label: 'FNF Pending',
    value: 'FNF_PENDING'
  },
  {
    label: 'Info / Doc Unavailable',
    value: 'INFO_OR_DOC_UNAVAILABLE'
  },
  { label: 'Other', value: 'OTHER' }
];

export const ADDR_CHECK_HOLD_REASON = [
  { label: 'Future Availability', value: 'FUTURE_AVAILABILITY' },
  {
    label: 'Info / Doc Unavailable',
    value: 'INFO_OR_DOC_UNAVAILABLE'
  },
  { label: 'Other', value: 'OTHER' }
];

export const REF_CHECK_HOLD_REASON = [
  {
    label: 'Currently Working',
    value: 'CURRENTLY_WORKING'
  },
  {
    label: 'Info Unavailable',
    value: 'INFO_UNAVAILABLE'
  },
  { label: 'Other', value: 'OTHER' }
];
export const HOLD_REASONS_REF_CHECK = {
  CURRENTLY_WORKING: 'Currently Working',
  INFO_UNAVAILABLE: 'Info Unavailable'
};
export const CLOSE_CHECK_REASONS = [
  { label: 'Check no longer required', value: 'CHECK_NO_LONGER_REQUIRED' },
  { label: 'Candidate is not joining', value: 'CANDIDATE_IS_NOT_JOINING' },
  { label: 'Other', value: 'OTHER' }
];

// CA and SA both hold check reasons added here
export const HOLD_CHECK_REASONS_MAPPING = {
  CURRENTLY_STUDYING: 'Currently studying',
  AWAITING_RESULTS: 'Awaiting results',
  COMPLETED_BUT_DOCS_NOT_ISSUED: 'Completed, yet to be issued docs',
  INFO_OR_DOC_UNAVAILABLE: 'Info / Doc Unavailable',
  CLIENT_REQUEST: 'Client Request',
  ISSUE_WITH_SOURCE: 'Issue with Source',
  FUTURE_AVAILABILITY: 'Future Availability',
  FNF_PENDING: 'FNF Pending',
  OTHER: 'Other'
};

export const CHECK_NAME_BY_ID = {
  1: 'identity',
  2: 'history',
  3: 'address',
  4: 'employment',
  5: 'education',
  6: 'world',
  7: 'drug',
  8: 'credit',
  9: 'reference',
  10: 'custom_check'
};
export const ADDRESS_TYPE_MAPPING = {
  'Current Address': 'Current Address',
  current_address: 'Current Address',
  'Permanent Address': 'Permanent Address',
  permanent_address: 'Permanent Address',
  'Other Address': 'Other Address'
};
export const ADDRESS_VERIFICATION_TYPE_MAPPING = {
  PHYSICAL: 'Physical',
  POSTAL: 'Postal',
  DIGITAL: 'Digital'
};
export const UPSELL_DISCOUNT_BANNER_DATE = '2024-09-10';

export const UPSELL_BANNER_DISPLAY_START_DATE = new Date('2024-11-06').setHours(0, 0, 0, 0);
export const UPSELL_BANNER_DISPLAY_END_DATE = new Date('2024-11-12').setHours(23, 59, 59, 999);
