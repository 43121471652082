import * as Yup from 'yup';
import { getTrimmedValue } from '../../../../../utils/utilities';
import {
  employeeIdValidationSchema,
  getNameValidationSchema,
  getUANValidationSchema
} from '../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  full_name: getNameValidationSchema({ message: 'name', isRequired: true }),
  dob: Yup.string().when(['is_dob_optional'], {
    is: (is_dob_optional) => is_dob_optional,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Date of Birth is required')
  }),
  father_name: Yup.string().when(['is_father_name_optional'], {
    is: (is_father_name_optional) => is_father_name_optional,
    then: getNameValidationSchema({
      message: `Father's name`
    }),
    otherwise: getNameValidationSchema({
      message: `Father's name`,
      isRequired: true,
      isRequiredMessage: `Please enter Father's name`
    })
  }),
  employee_id: Yup.string()
    .transform((c) => getTrimmedValue(c, true))
    .when('employee_id_exists', {
      is: true,
      then: Yup.string('Employee ID already exists'),
      otherwise: employeeIdValidationSchema({
        message: 'Employee ID',
        isRequired: false
      })
    }),
  uan_number: Yup.string().when(['is_uan_mandatory', 'uan_not_available'], {
    is: (is_uan_mandatory, uan_not_available) => is_uan_mandatory && !uan_not_available,
    then: getUANValidationSchema({ isRequired: true, isNullable: false }).concat(
      Yup.string().required('Provide a valid UAN')
    ),
    otherwise: getUANValidationSchema({ isRequired: false, isNullable: true })
  }),
  gender: Yup.string().when('is_sex_optional', {
    is: false,
    then: Yup.string().required('Sex is required')
  })
});
