import React from 'react';
import classes from './MarketingBanner.module.scss';
import Image from '../../core-components/Image';

const MarketingBanner = () => {
  return (
    <Image
      onClick={() => window.open('https://cutt.ly/deZAUlwF', '_blank')}
      className={classes.imageContainer}
      name={'marketingBanner5.png'}
    />
  );
};

export default MarketingBanner;
