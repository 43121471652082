import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import moment from 'moment';
import Image from '../../../../../../core-components/Image';
import styles from './EmploymentSaved.module.scss';
import { isAllRefFilled, isAllStepsCompleted } from '../EmploymentHelper';
import { capitalizeName } from '../../../../../CompanyAdmin/frontend-common/utils/Utilities';

export default ({
  item,
  handleEmpDelete,
  handleEmpEdit,
  showhr,
  index,
  loading,
  isLwdOptionalInEmployment
}) => {
  const [showBadge, setShowBadge] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const { step_1 } = item;
  const { duration } = step_1;
  let endDate = duration.currently_working
    ? 'Present'
    : moment(step_1.duration.end_date, 'YYYY-MM-DD', true).isValid()
    ? moment(step_1.duration.end_date, 'YYYY-MM-DD').format('DD MMM YYYY')
    : isLwdOptionalInEmployment
    ? 'NA'
    : 'Invalid Date';
  let startDate = moment(step_1.duration.start_date, 'YYYY-MM-DD', true).isValid()
    ? moment(step_1.duration.start_date, 'YYYY-MM-DD').format('DD MMM YYYY')
    : 'Invalid Date';
  const handleDeleteBadge = (index) => {
    handleEmpDelete(index);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowBadge(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const getCityValue = (data) => {
    if (data.endsWith('*')) {
      return data.slice(0, -1);
    }
    return data;
  };

  return (
    <>
      {showhr && <hr />}
      <Row className={styles.savedEmpInfo}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col lg={8} md={8} sm={8} xs={8}>
              <div className={styles.empDisplayTitle}>
                {capitalizeName(step_1.designation) + ' | ' + step_1.company_name}
              </div>
              <div className={styles.empDisplaySubTitle}>
                {getCityValue(step_1.city) +
                  ', ' +
                  step_1.country +
                  ' | ' +
                  startDate +
                  ' - ' +
                  endDate}
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} className={styles.actionBtnContainer}>
              {showBadge ? (
                <Badge
                  onClick={() => {
                    handleDeleteBadge(index);
                  }}
                  pill
                  variant='danger'
                  className={styles.deleteBadge}
                  ref={wrapperRef}
                  data-testid='confirm-delete-button'
                >
                  Delete?
                </Badge>
              ) : (
                <>
                  <Image
                    className={styles.editIcon}
                    name='BgvIdEdit.png'
                    data-testid='edit-button'
                    onClick={() => handleEmpEdit(index)}
                  />
                  <Image
                    className={styles.deleteIcon}
                    name='BgvIdDelete.svg'
                    data-testid='delete-button'
                    onClick={() => {
                      setShowBadge(true);
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
          {(!isAllStepsCompleted(item) || !isAllRefFilled(item)) && !loading && (
            <span className={styles.incompleteText}>
              Please provide complete details for this Employment.
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};
