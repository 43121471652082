// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.K8tssfaYdqUXa3vD9AX9 {
  color: #000000;
  font-weight: 500;
  font-size: 22px;
}

.oZrPbve1x03F0GDVZzrk {
  font-weight: 600;
  font-size: 16px;
}

.ytVOD_yGDOsgjhOrVLnw {
  font-size: 14px;
  color: #999999;
}

.z_kfmspTiOBfq9j7jAUU {
  margin-top: 30px;
}

.MJeatBHOCCaMXykGW6XJ {
  width: 240px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 2px;
  text-align: center;
  color: #2755fe;
}
@media screen and (max-width: 501px) {
  .MJeatBHOCCaMXykGW6XJ {
    width: 100%;
  }
}

.ii9xZ3xY1LOPhEgKkKDJ {
  width: 211px;
  height: 117px;
  background: #f5f7ff;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 10px;
}
@media screen and (max-width: 601px) {
  .ii9xZ3xY1LOPhEgKkKDJ {
    margin-top: 15px;
  }
}
.ii9xZ3xY1LOPhEgKkKDJ .OJEumb8S37KpnO8Q_wTd {
  text-align: center;
}
.ii9xZ3xY1LOPhEgKkKDJ .OJEumb8S37KpnO8Q_wTd .z3qnh6SUzxAjL3hpMRRt {
  color: white;
}
.ii9xZ3xY1LOPhEgKkKDJ .OJEumb8S37KpnO8Q_wTd .O1W5srzHvzo3sFF8MC0G {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: -15px;
  margin-left: 170px;
  text-align: center;
  border-radius: 50%;
  background: white;
}
.ii9xZ3xY1LOPhEgKkKDJ .Xs5D3XdhIaRDUv1diMXN {
  text-align: center;
  margin-top: 30px;
}
.ii9xZ3xY1LOPhEgKkKDJ .Xs5D3XdhIaRDUv1diMXN .z3qnh6SUzxAjL3hpMRRt {
  color: white;
}
.ii9xZ3xY1LOPhEgKkKDJ .Xs5D3XdhIaRDUv1diMXN .O1W5srzHvzo3sFF8MC0G {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 90px;
  background: white;
}

.p3k7FZki6OfZCMx7Fa7T {
  padding: 0;
}
.p3k7FZki6OfZCMx7Fa7T .GWSHmSHIqrn3eESOabot {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #2755fe;
  padding: 8px 18px;
  margin-bottom: 16px;
  text-align: center;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}
.p3k7FZki6OfZCMx7Fa7T .GWSHmSHIqrn3eESOabot:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}

.XBU6PUzv7_wc3atvgaG6 {
  margin-top: -11px;
  color: red;
  font-weight: 200;
  font-size: 14px;
}

.awKo5qHvYKGJ3eSZhEmP {
  position: absolute;
  left: 310px;
  margin-top: 10px;
}
@media screen and (max-width: 501px) {
  .awKo5qHvYKGJ3eSZhEmP {
    left: 85%;
  }
}

.MklnbwgKGXA_9tXOf_e5 {
  position: absolute;
  display: flex;
}
.MklnbwgKGXA_9tXOf_e5 .oiQJo5T7Fr6fWfLMxNgg {
  margin-left: 3px;
  background: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.Eo_pp4JLTP9GhZgKffGx {
  position: absolute;
  bottom: 4px;
  right: 40px;
}
.Eo_pp4JLTP9GhZgKffGx .oiQJo5T7Fr6fWfLMxNgg {
  margin-right: 3px;
  background: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.mbom_GlzbOwIy29pWrUG {
  margin: 20px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .mbom_GlzbOwIy29pWrUG {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
  .mbom_GlzbOwIy29pWrUG button {
    height: 40px;
    margin: 0 !important;
  }
  .mbom_GlzbOwIy29pWrUG .r1Mr5QauxFPodCjKSbI1 {
    flex: 1;
  }
  .mbom_GlzbOwIy29pWrUG .ldUySUF8LWd49GGB2HQw {
    flex: 1;
  }
}

.p9uRIM2lWEp9g3qCxHqA {
  padding-bottom: 40px !important;
  min-height: 350px;
}
@media screen and (max-width: 601px) {
  .p9uRIM2lWEp9g3qCxHqA {
    padding-bottom: 65px !important;
  }
}

.EltITxHzVl3uzh7RWu99 {
  display: flex;
  position: relative;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.EltITxHzVl3uzh7RWu99 .e_M8bhQZBO3pf3A46Icd {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}
.EltITxHzVl3uzh7RWu99 span {
  margin: 5px;
}
.EltITxHzVl3uzh7RWu99 img {
  height: 100%;
  width: 100%;
}
.EltITxHzVl3uzh7RWu99 .Mo3vLUmQ5U3KQpyVZ4lX {
  font-size: 20px;
  font-weight: 500;
  color: #faad14;
}
.EltITxHzVl3uzh7RWu99 .qsNFK2skSBTWeYErjYtS {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.EltITxHzVl3uzh7RWu99 .qsNFK2skSBTWeYErjYtS .GMtlke2otd2RAW8WFOdJ {
  background: transparent;
  color: #2755fe;
  font-style: normal;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  margin: 0px 10px 5px 10px;
}
.EltITxHzVl3uzh7RWu99 .qsNFK2skSBTWeYErjYtS .J5rR4mVwBloaZhJ4GQHt {
  background: #2755fe;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0px 10px 5px 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"identityTitle": `K8tssfaYdqUXa3vD9AX9`,
	"warningHeading": `oZrPbve1x03F0GDVZzrk`,
	"warningSubHeading": `ytVOD_yGDOsgjhOrVLnw`,
	"row": `z_kfmspTiOBfq9j7jAUU`,
	"addBtn": `MJeatBHOCCaMXykGW6XJ`,
	"idPlaceHolder": `ii9xZ3xY1LOPhEgKkKDJ`,
	"idMaxError": `OJEumb8S37KpnO8Q_wTd`,
	"errorTag": `z3qnh6SUzxAjL3hpMRRt`,
	"errorRefresh": `O1W5srzHvzo3sFF8MC0G`,
	"idUploadText": `Xs5D3XdhIaRDUv1diMXN`,
	"takePhotoBtnContainer": `p3k7FZki6OfZCMx7Fa7T`,
	"takePhotoBtn": `GWSHmSHIqrn3eESOabot`,
	"error": `XBU6PUzv7_wc3atvgaG6`,
	"ocrSpinner": `awKo5qHvYKGJ3eSZhEmP`,
	"uploadFunctionality": `MklnbwgKGXA_9tXOf_e5`,
	"functionBtn": `oiQJo5T7Fr6fWfLMxNgg`,
	"passwordProtectIcon": `Eo_pp4JLTP9GhZgKffGx`,
	"identitySubmitBtn": `mbom_GlzbOwIy29pWrUG`,
	"activeBtn": `r1Mr5QauxFPodCjKSbI1`,
	"disabledBtn": `ldUySUF8LWd49GGB2HQw`,
	"modalBody": `p9uRIM2lWEp9g3qCxHqA`,
	"bgvEmpDocumentWarning": `EltITxHzVl3uzh7RWu99`,
	"warningModalCloseButton": `e_M8bhQZBO3pf3A46Icd`,
	"modalWarningHeader": `Mo3vLUmQ5U3KQpyVZ4lX`,
	"modalWarningButtonContain": `qsNFK2skSBTWeYErjYtS`,
	"ignoreSaveButton": `GMtlke2otd2RAW8WFOdJ`,
	"modalWarningButton": `J5rR4mVwBloaZhJ4GQHt`
};
export default ___CSS_LOADER_EXPORT___;
