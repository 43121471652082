function isLocallyServed() {
  return window.location.hostname.includes('local') || window.location.hostname.includes('ngrok');
}

function isDev2() {
  return window.location.hostname.includes('dev-2-portal');
}

function isDev3() {
  return window.location.hostname.includes('dev-3-portal');
}

function isDev4() {
  return window.location.hostname.includes('dev-4-portal');
}

function isDev5() {
  return window.location.hostname.includes('dev-5-portal');
}

function isDev6() {
  return window.location.hostname.includes('dev-6-portal');
}

function isAcceptance() {
  return window.location.hostname.includes('acceptance-portal');
}

function isAcceptance2() {
  return window.location.hostname.includes('acceptance-2-portal');
}

function isRevamp() {
  return window.location.hostname.includes('beta-acceptance-2-portal');
}

function getEnv() {
  return isLocallyServed()
    ? 'local'
    : isRevamp()
    ? 'revamp'
    : isDev2()
    ? 'dev-2'
    : isDev3()
    ? 'dev-3'
    : isDev4()
    ? 'dev-4'
    : isDev5()
    ? 'dev-5'
    : isDev6()
    ? 'dev-6'
    : isAcceptance()
    ? 'accept'
    : isRevamp()
    ? 'revamp'
    : isAcceptance2()
    ? 'accept-2'
    : process.env.NODE_ENV === 'development'
    ? 'dev'
    : 'prod';
}

const AppHelper = {
  isLocallyServed,
  isDev2,
  isDev3,
  isDev4,
  isAcceptance,
  isAcceptance2,
  isRevamp,
  getEnv,
  isDev5,
  isDev6
};

export default AppHelper;
