import * as Yup from 'yup';
import moment from 'moment';
import {
  employeeIdValidationSchema,
  getCityNameValidation,
  getStrictNameValidationSchema
} from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  is_employee_id_not_available: Yup.boolean(),
  company_name: getStrictNameValidationSchema({ message: 'Company Legal Name', isRequired: true }),
  company_brand_name: getStrictNameValidationSchema({
    message: 'Company Brand Name',
    isRequired: false
  }),
  city: Yup.string().required(''),
  designation: getStrictNameValidationSchema({ message: 'designation', isRequired: true }),
  employee_id: Yup.string().when('is_employee_id_not_available', {
    is: false,
    then: employeeIdValidationSchema({ message: 'Employee ID', isRequired: true })
  }),
  // .matches(/^[^-\s][a-zA-Z0-9_\s-]+$/, 'Please enter a valid Employee ID'),
  start_date: Yup.mixed()
    .required('Provide a valid Start date')
    .test({
      name: 'start_date',
      message: 'Please enter a valid date',
      test: (value) => {
        if (value !== null) {
          return moment(value, 'YYYY-MM-DD', true).isValid();
        } else {
          return false;
        }
      }
    }),
  currently_working: Yup.boolean(),
  end_date: Yup.mixed().when(['currently_working', 'is_lwd_optional'], {
    is: (currently_working, is_lwd_optional) => currently_working && !is_lwd_optional,
    then: Yup.mixed()
      .required('Provide a valid tentative Last Working date')
      .test({
        name: 'end_date',
        message: 'Provide a valid tentative Last Working date',
        test: (value) => {
          return value !== null && moment(value, 'YYYY-MM-DD', true).isValid();
        }
      }),
    otherwise: Yup.mixed().when('is_lwd_optional', {
      is: false,
      then: Yup.mixed()
        .required('Provide a valid Last Working date')
        .test({
          name: 'end_date',
          message: 'Provide a valid Last Working date',
          test: (value) => {
            return value !== null && moment(value, 'YYYY-MM-DD', true).isValid();
          }
        })
    })
  }),
  reason: Yup.string().nullable().trim().max(500, 'Max character limit (500) reached')
});
